import React, { useState } from 'react';
import axios from 'axios';
import './style.css';

function MeusTestesPage() {
  const [textoOriginal, setTextoOriginal] = useState('');
  const [textoReescrito, setTextoReescrito] = useState('');
  const [textoFinal, setTextoFinal] = useState('');

  const handleInputChange = (e) => {
    setTextoOriginal(e.target.value);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        {
          model: 'gpt-3.5-turbo',
          messages: [
            {
              role: 'user',
              content: `Reescreva o seguinte texto de forma que pareça integralmente ter sido escrito por um ser humano. Use um estilo casual, mas tentando ser formal, com menos coesão e algumas pequenas interrupções na fluência. Importante parecer com um aluno de faculdade escrevendo seu tcc: "${textoOriginal}"`,
            },
          ],
          max_tokens: 300,
          temperature: 0.7,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer COLOCAR-AQUI-API`,
          },
        }
      );

      const reescrito = response.data.choices[0].message.content;
      setTextoReescrito(reescrito);

      // Permitir que o usuário edite manualmente
      setTextoFinal(reescrito);
    } catch (error) {
      console.error('Erro ao chamar a API:', error);
      setTextoReescrito('Ah, algo deu errado... não consegui reescrever o texto.');
    }
  };

  const handleManualEdit = (e) => {
    setTextoFinal(e.target.value);
  };

  return (
    <div className="meus-testes-container">
      <form onSubmit={handleFormSubmit}>
        <label>
          Texto Original:
          <textarea
            value={textoOriginal}
            onChange={handleInputChange}
            placeholder="Então, insira o texto aqui..."
            required
          />
        </label>
        <button type="submit">Reescrever</button>
      </form>
      {textoReescrito && (
        <div className="resposta-container">
          <h3>Texto Reescrito (IA):</h3>
          <textarea
            value={textoFinal}
            onChange={handleManualEdit}
          />
        </div>
      )}
      {textoFinal && (
        <div className="resposta-container">
          <h3>Texto Final (Ajustado Manualmente):</h3>
          <p>{textoFinal}</p>
        </div>
      )}
    </div>
  );
}

export default MeusTestesPage;
