import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { apiUrl } from '../../../config/apiConfig';
import './style.css';
import { useUser } from '../../../contexts/userContext';
import Header from '../../Header';

function Participantes() {
  const { user, empresa } = useUser();

  const [participantes, setParticipantes] = useState([]);
  const [selectedParticipante, setSelectedParticipante] = useState(null);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [cpfError, setCpfError] = useState('');
  const [formValues, setFormValues] = useState({
    name: '',
    email: '',
    telefone: '',
    endereco: '',
    tipo: 'Cliente',  // Tipo padrão definido como 'Cliente'
    state: '',
    city: '',
    cpf: ''
  });
  const [showForm, setShowForm] = useState(false); // Estado para controlar a exibição do formulário

  useEffect(() => {
    if(empresa.id){
      fetchParticipantes();
      fetchStates();
    }
  }, [empresa]);

  useEffect(() => {
    if (formValues.state) {
      fetchCities(formValues.state);
    }
  }, [formValues.state]);

  // const fetchParticipantes = async () => {
  //   try {
  //     const response = await axios.get(`${apiUrl}/card/participantes/${empresa.id}`);
  //     setParticipantes(response.data);
  //   } catch (error) {
  //     console.error('Erro ao buscar participantes:', error);
  //   }
  // };


  const fetchParticipantes = async () => {
    try {
      const response = await axios.get(`${apiUrl}/card/participantes/by-entity/${user.id}`);
      setParticipantes(response.data);
    } catch (error) {
      console.error('Erro ao buscar participantes:', error);
    }
  };
  

  


  const fetchStates = async () => {
    try {
      const response = await axios.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados?orderBy=nome');
      setStates(response.data.map(state => ({ sigla: state.sigla, nome: state.nome })));
    } catch (error) {
      console.error('Erro ao buscar estados:', error);
    }
  };

  const fetchCities = async (state) => {
    try {
      const response = await axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${state}/municipios?orderBy=nome`);
      setCities(response.data.map(city => ({ id: city.id, nome: city.nome })));
    } catch (error) {
      console.error('Erro ao buscar cidades:', error);
    }
  };

  const validateCPF = (cpf) => {
    let sum = 0;
    let remainder;
    cpf = cpf.replace(/[^\d]+/g, ''); // Remove caracteres não numéricos

    if (cpf.length !== 11 || /^(\d)\1{10}$/.test(cpf)) return false;

    for (let i = 1; i <= 9; i++) sum += parseInt(cpf.substring(i - 1, i)) * (11 - i);
    remainder = (sum * 10) % 11;
    if (remainder === 10 || remainder === 11) remainder = 0;
    if (remainder !== parseInt(cpf.substring(9, 10))) return false;

    sum = 0;
    for (let i = 1; i <= 10; i++) sum += parseInt(cpf.substring(i - 1, i)) * (12 - i);
    remainder = (sum * 10) % 11;
    if (remainder === 10 || remainder === 11) remainder = 0;
    if (remainder !== parseInt(cpf.substring(10, 11))) return false;

    return true;
  };

  const handleCreateParticipante = async () => {
    if (!formValues.cpf || !validateCPF(formValues.cpf)) {
      setCpfError('CPF inválido');
      return;
    }

    setCpfError('');

    const newParticipante = {
      ...formValues,
      empresa_id: empresa.id,
      entity_id: user.id,
    };

    try {
      await axios.post(`${apiUrl}/card/participantes/create`, newParticipante);
      alert('Participante criado com sucesso!');
      fetchParticipantes();
      resetForm();
    } catch (error) {
      console.error('Erro ao criar o participante:', error);
      alert('Erro ao criar o participante. Verifique o console para mais detalhes.');
    }
  };

  const handleUpdateParticipante = async () => {
    if (!formValues.cpf || !validateCPF(formValues.cpf)) {
      setCpfError('CPF inválido');
      return;
    }

    setCpfError('');

    const updatedParticipante = {
      ...formValues,
      empresa_id: empresa.id,
      entity_id: user.id,
    };

    try {
      await axios.put(`${apiUrl}/card/participantes/update/${selectedParticipante.id}`, updatedParticipante);
      alert('Participante atualizado com sucesso!');
      fetchParticipantes();
      resetForm();
    } catch (error) {
      console.error('Erro ao atualizar o participante:', error);
      alert('Erro ao atualizar o participante. Verifique o console para mais detalhes.');
    }
  };

  const resetForm = () => {
    setFormValues({
      name: '',
      email: '',
      telefone: '',
      endereco: '',
      tipo: 'Cliente',  // Resetando para o tipo padrão 'Cliente'
      state: '',
      city: '',
      cpf: ''
    });
    setSelectedParticipante(null);
    setShowForm(false); // Esconde o formulário ao resetar
  };

  const handleEditParticipante = (participante) => {
    setSelectedParticipante(participante);
    setFormValues(participante);
    setShowForm(true); // Mostra o formulário ao editar
  };

  const handleDeleteParticipante = async (id) => {
    const userConfirmed = window.confirm(`Você tem certeza que deseja excluir este participante?`);
    if (!userConfirmed) return;

    try {
      await axios.delete(`${apiUrl}/card/participantes/delete/${id}`);
      alert('Participante excluído com sucesso!');
      fetchParticipantes();
      resetForm();
    } catch (error) {
      console.error('Erro ao excluir o participante:', error);
      alert('Erro ao excluir o participante. Verifique o console para mais detalhes.');
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (selectedParticipante) {
      handleUpdateParticipante();
    } else {
      handleCreateParticipante();
    }
  };

  return (
    <div className="participantes-page-container">
      <Header />

      <div className="participantes-list-container">
        <h2>Lista de Participantes</h2>
        <button className="participantes-create-button" onClick={() => setShowForm(true)}>Criar Novo Participante</button>
        <ul>
          {participantes.map(participante => (
            <li key={participante.id} className="participantes-list-item">
              <span>{participante.name} - {participante.email}</span>
              <button className="participantes-edit-button" onClick={() => handleEditParticipante(participante)}>Editar</button>
            </li>
          ))}
        </ul>
      </div>

      {showForm && (
        <div className="participantes-modal-overlay">
          <div className="participantes-modal-content">
            <h2>{selectedParticipante ? 'Editar Participante' : 'Novo Participante'}</h2>
            <form onSubmit={handleSubmit}>
              <label>Nome:</label>
              <input
                type="text"
                value={formValues.name}
                onChange={(e) => setFormValues({ ...formValues, name: e.target.value })}
                className="participantes-input"
              />
              <label>Email:</label>
              <input
                type="email"
                value={formValues.email}
                onChange={(e) => setFormValues({ ...formValues, email: e.target.value })}
                className="participantes-input"
              />
              <label>Telefone:</label>
              <input
                type="text"
                value={formValues.telefone}
                onChange={(e) => setFormValues({ ...formValues, telefone: e.target.value })}
                className="participantes-input"
              />
              <label>Endereço:</label>
              <input
                type="text"
                value={formValues.endereco}
                onChange={(e) => setFormValues({ ...formValues, endereco: e.target.value })}
                className="participantes-input"
              />
              <label>Tipo de Participante:</label>
              <select
                value={formValues.tipo}
                onChange={(e) => setFormValues({ ...formValues, tipo: e.target.value })}
                className="participantes-select"
              >
                <option value="Cliente">Cliente</option>
                <option value="Fornecedor">Fornecedor</option>
                <option value="Parceiro">Parceiro</option>
                <option value="Colaborador">Colaborador</option>
                <option value="Investidor">Investidor</option>
                <option value="Outros">Outros</option>
              </select>
              <label>Estado:</label>
              <select
                value={formValues.state}
                onChange={(e) => setFormValues({ ...formValues, state: e.target.value })}
                className="participantes-select"
              >
                <option value="">Selecione o estado</option>
                {states.map(state => (
                  <option key={state.sigla} value={state.sigla}>{state.nome}</option>
                ))}
              </select>
              <label>Cidade:</label>
              <select
                value={formValues.city}
                onChange={(e) => setFormValues({ ...formValues, city: e.target.value })}
                className="participantes-select"
                disabled={!formValues.state}
              >
                <option value="">Selecione a cidade</option>
                {cities.map(city => (
                  <option key={city.id} value={city.nome}>{city.nome}</option>
                ))}
              </select>
              <label>CPF:</label>
              <input
                type="text"
                value={formValues.cpf}
                onChange={(e) => setFormValues({ ...formValues, cpf: e.target.value })}
                onBlur={() => validateCPF(formValues.cpf)}
                className="participantes-input"
              />
              {cpfError && <div className="participantes-error-message">{cpfError}</div>}
              <div className="participantes-form-buttons">

                <button type="button" className="participantes-cancel-button" onClick={resetForm}>Cancelar</button>
                {selectedParticipante && (
                  <button type="button" className="participantes-delete-button" onClick={() => handleDeleteParticipante(selectedParticipante.id)}>Excluir</button>
                )}
                <button type="submit" className="participantes-submit-button">{selectedParticipante ? 'Atualizar' : 'Criar'}</button>


              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default Participantes;
