import React, { useState, useEffect, useRef } from 'react';
import { gapi } from 'gapi-script';
import { BrowserMultiFormatReader } from '@zxing/library';
import PDFViewer from '../PDFViewer'; // Importe o PDFViewer
import './style.css';

import { MdAutorenew, MdOutlineManageSearch } from "react-icons/md";
import { RiBarcodeBoxLine } from "react-icons/ri";

const CLIENT_ID = '329127713016-94lonn22albknrpk2p1vkr7v2h7rcep7.apps.googleusercontent.com';
const API_KEY = 'AIzaSyCXZbla9QsrjblHCb_J4mO8MaAlvtaZrA4';
const FOLDER_ID = '1ngm-juUx3a2_wVl1FYkduIEteCnkAZOA';
const SCOPES = 'https://www.googleapis.com/auth/drive.readonly';

function OrdensProducao() {
  const [code, setCode] = useState('');
  const [tipo, setTipo] = useState('');
  const [files, setFiles] = useState([]);
  const [scanning, setScanning] = useState(false);
  const [facingMode, setFacingMode] = useState('environment');
  const [isSearching, setIsSearching] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false); // Novo estado para controlar o download
  const [selectedPdf, setSelectedPdf] = useState(null);
  const videoRef = useRef(null);

  useEffect(() => {
    const initClient = () => {
      gapi.client.init({
        apiKey: API_KEY,
        clientId: CLIENT_ID,
        discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/drive/v3/rest'],
        scope: SCOPES,
      }).then(() => {
        const authInstance = gapi.auth2.getAuthInstance();
        if (!authInstance.isSignedIn.get()) {
          authInstance.signIn();
        }
      });
    };
    gapi.load('client:auth2', initClient);
  }, []);

  const searchFile = () => {
    setIsSearching(true);

    gapi.client.drive.files.list({
      q: `'${FOLDER_ID}' in parents and name contains '${code}'`,
      fields: 'files(id, name)',
      key: API_KEY

    // gapi.client.drive.files.list({
    //   q: `'${FOLDER_ID}' in parents`,
    //   fields: 'files(id, name)',
    //   key: API_KEY
    
    }).then((response) => {
      const allFiles = response.result.files;
      const filteredFiles = allFiles.filter((file) => file.name.includes(code));
      setFiles(filteredFiles);
      setIsSearching(false);
      if (filteredFiles.length === 0) {
        alert('Nenhum arquivo encontrado');
      }
    }).catch((error) => {
      console.error('Erro ao buscar o arquivo:', error);
      setIsSearching(false);
    });
  };

  const stopCamera = () => {
    const stream = videoRef.current?.srcObject;
    if (stream) {
      stream.getTracks().forEach(track => track.stop());
      videoRef.current.srcObject = null;
    }
    setScanning(false);
  };

  const handleFileClick = (fileId) => {
    stopCamera();
    setIsDownloading(true); // Ativa o estado de download

    const fileUrl = `https://www.googleapis.com/drive/v3/files/${fileId}?alt=media&key=${API_KEY}`;

    fetch(fileUrl)
      .then(response => response.arrayBuffer())
      .then(buffer => {
        const base64String = btoa(
          new Uint8Array(buffer).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ''
          )
        );
        setSelectedPdf(`data:application/pdf;base64,${base64String}`);
        setIsDownloading(false); // Desativa o estado de download após o término
      })
      .catch(error => {
        console.error('Erro ao obter o arquivo PDF:', error);
        setIsDownloading(false); // Desativa o estado de download em caso de erro
      });
  };

  const startScanner = async () => {
    const codeReader = new BrowserMultiFormatReader();
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: { facingMode }
      });
      videoRef.current.srcObject = stream;

      videoRef.current.onloadedmetadata = () => {
        videoRef.current.play().catch((error) => {
          console.error('Erro ao tentar reproduzir o vídeo:', error);
        });
      };

      codeReader.decodeFromVideoDevice(null, videoRef.current, (result, err) => {
        if (result) {
          const scannedData = result.text.split('|')[0];
          setCode(scannedData);
          stopCamera();
        }
        if (err) {
          console.error(err);
        }
      });
    } catch (err) {
      console.error('Erro ao acessar a câmera:', err);
    }
  };

  const toggleCamera = () => {
    setFacingMode((prevMode) => (prevMode === 'environment' ? 'user' : 'environment'));
  };

  useEffect(() => {
    if (scanning) {
      startScanner();
    }
  }, [scanning, facingMode]);

  const adjustVideoProportions = () => {
    const videoElement = videoRef.current;
    const stream = videoElement?.srcObject;

    if (stream) {
      const track = stream.getVideoTracks()[0];
      const settings = track.getSettings();

      const width = settings.width || 600;
      const height = Math.floor(width / 4);

      videoElement.style.width = `${width}px`;
      videoElement.style.height = `${height}px`;
    }
  };

  useEffect(() => {
    if (scanning) {
      adjustVideoProportions();
    }
  }, [scanning]);

  const closePdfViewer = () => {
    setSelectedPdf(null);
  };



  return (
    <div className="ordens-producao-container-ordens">
      <header className="header-container-landing-page">
        <div className="header-logo-center-landing-page">
          <label className="header-logo-center-label-landing-page">SyncsCRM</label>
        </div>
        <div className="header-menu-right-landing-page">
          {/* Menu or additional header items can go here */}
        </div>
      </header>

      <div className="App-header">
        <h3 className='title-ordens'>Ordens de Produção</h3>

        <div className='ordens-producao-container'>
          <div className="input-group">
            <label className='label-input-ordens' htmlFor="codigo">Código</label>
            <input
              id="codigo"
              className='input-ordens-producao'
              type="text"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              placeholder="Código..."
            />
          </div>

          <div className="input-group">
            <label className='label-input-ordens' htmlFor="tipo">Tipo</label>
            <input
              id="tipo"
              className='input-ordens-producao'
              type="text"
              value={tipo}
              onChange={(e) => setTipo(e.target.value)}
              placeholder="Tipo..."
            />
          </div>

          <div className='btns-ordens-producao-container'>
          <button className='btn-ordens-producao' onClick={searchFile}>
            <MdOutlineManageSearch className='icons-ordens-producao' />
          </button>
          <button className='btn-ordens-producao-qrcode' onClick={() => setScanning(true)}>
            <RiBarcodeBoxLine className='icons-ordens-producao' />
          </button>
          </div>

        </div>

        {scanning && (
          <div className="overlay">
            <div className="scanner-container">
              <video className='cam-barcode' ref={videoRef} />
              <div className='footer-cam'>
                <button className='btn-ordens-producao' onClick={toggleCamera}>
                  <MdAutorenew style={{ fontSize: '30px' }} />
                </button>
                <button onClick={stopCamera} className="cancel-button">Cancelar</button>
              </div>
            </div>
          </div>
        )}

        <div>
          {isSearching ? (
            <p>Buscando arquivo...</p>
          ) : (
            files.length > 0 && (
              <ul>
                {files.map((file) => (
                  <li key={file.id}>
                    <button className='item-list-ordens' onClick={() => handleFileClick(file.id)}>
                      {file.name}
                    </button>
                  </li>
                ))}
              </ul>
            )
          )}
        </div>

        {isDownloading && (
          <div className="overlay">
            <div className="downloading-message">
              <p>Baixando...</p>
            </div>
          </div>
        )}

        {selectedPdf && (
          <PDFViewer pdfUrl={selectedPdf} initialSearchTerm={tipo} onClose={closePdfViewer} />
        )}
      </div>
    </div>
  );
}

export default OrdensProducao;
